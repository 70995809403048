import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useSwipeable } from "react-swipeable"

import CatWatching from "../assets/cat-watching.svg"

const SliderTech = () => {
  const data = useStaticQuery(graphql`
    query SliderTechQuery {
      allSliderTechDataJson {
        edges {
          node {
            id
            items {
              id
              title
              icon
            }
          }
        }
      }
    }
  `)

  const sliderData = data.allSliderTechDataJson.edges

  const [step, setStep] = useState(0)

  return (
    <section className="stack">
      <div className="stack__wrapper container">
        <div className="stack__block">
          <h2 className="stack__title">Technical stack</h2>

          <ul className="stack-list">
            <li
              className={`stack-list__item${step === 0 ? " active" : ""}`}
              onClick={() => setStep(0)}
            >
              Web applications
            </li>
            <li
              className={`stack-list__item${step === 1 ? " active" : ""}`}
              onClick={() => setStep(1)}
            >
              Mobile applications
            </li>
            <li
              className={`stack-list__item${step === 2 ? " active" : ""}`}
              onClick={() => setStep(2)}
            >
              Server side
            </li>
          </ul>

          <p className="stack__text">
          With a world-class  IT-team, Movadex guarantess high-quality customer software tailored to your business needs. We design, build, and implemnet software and design of any complexity.
          </p>
        </div>

        {sliderData.map(({ node }, index) =>
          step === index ? (
            <div className="stack-slider" key={node.id}>
              {node.items.map(elem => (
                <div className="stack-slider__item" key={elem.id}>
                  <img className="stack-slider__icon" src={elem.icon} alt="" />
                  <h4 className="stack-slider__title">{elem.title}</h4>
                </div>
              ))}

              <div className="stack-slider__item">
                <img className="stack-slider__cat" src={CatWatching} alt="" />
              </div>
            </div>
          ) : null
        )}
      </div>
    </section>
  )
}

export default SliderTech
