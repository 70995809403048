import React from 'react';

import CarouselReviews from './CarouselReviews';
import SliderClients from './SliderClients';
import { useMediaQuery } from './isMediaSmall';

const ReviewsSection = () => {

    const isMediaSmall = useMediaQuery("(max-width: 1140px)");

    return (
        <>
        {isMediaSmall ? <SliderClients/> : <SliderClients/>}
        </>
    );
}

export default ReviewsSection;
