import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import { useMediaQuery } from './isMediaSmall';

const SliderServices = () => {
    const data = useStaticQuery(graphql`
    query SliderServicesQuery {
        allSliderServicesDataJson {
            edges {
                node {
                    id
                    slug
                    title
                    text
                }
            }
        }
    }    
    `);

    const sliderData = data.allSliderServicesDataJson.edges;

    const [step, setStep] = useState(0);
    const total = sliderData.length;

    const isMediaSmall = useMediaQuery("(max-width: 1140px)");

    const changeSlide = type => {
        switch (type) {
            case "prev":
                if(step === 0) {
                    setStep(0)
                } else {
                    setStep(step - 1);
                }
                break;

            case "next":
                if(step === total - 1) {
                    setStep(step)
                } else {
                    setStep(step + 1);
                }
                break;
            default:
                break;
        }
    }

    const getSvgIcon = id => {
        switch (id) {
            case 0:
                return(
                    <svg className="services-slider-svg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="services-slider-svg__path services-slider-svg__path_fill" fillRule="evenodd" clipRule="evenodd" d="M64.214 22.8828L75.0959 44.6467C76.1517 46.7582 76.1517 49.2435 75.0959 51.3549L64.214 73.1189L59.7418 70.8828L70.6238 49.1189C70.9757 48.4151 70.9757 47.5866 70.6238 46.8828L59.7418 25.1189L64.214 22.8828ZM0.696476 45.0725L10.9601 22.9488L15.4957 25.0529L5.23216 47.1766C4.91967 47.8502 4.92278 48.6279 5.24066 49.299L15.4872 70.9306L10.9685 73.0711L0.721967 51.4394C-0.231656 49.4262 -0.240998 47.0932 0.696476 45.0725Z" fill="white"/>
                        <path className="services-slider-svg__path services-slider-svg__path_fill" fillRule="evenodd" clipRule="evenodd" d="M52.2202 24.3549L28.2202 73.1049L23.7344 70.8965L47.7344 22.1465L52.2202 24.3549Z" fill="white"/>
                    </svg>
                );
            case 1:
                return(
                    <svg className="services-slider-svg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                        <path className="services-slider-svg__path" d="M42 14.5H5C3.61929 14.5 2.5 15.6193 2.5 17V79C2.5 80.3807 3.61929 81.5 5 81.5H42C43.3807 81.5 44.5 80.3807 44.5 79V17C44.5 15.6193 43.3807 14.5 42 14.5Z" stroke="white" strokeWidth="5"/>
                        <path className="services-slider-svg__path" d="M71 12V84" stroke="white" strokeWidth="5"/>
                        <path className="services-slider-svg__path" d="M1 71H46" stroke="white" strokeWidth="5"/>
                        </g>
                        <defs>
                        <clipPath id="clip0">
                        <rect width="96" height="96" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                );
            case 2:
                return(
                    <svg className="services-slider-svg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="services-slider-svg__path services-slider-svg__path_fill" fillRule="evenodd" clipRule="evenodd" d="M25.2227 7.46094L49.6831 44.1516C51.2017 46.4295 51.3585 49.3539 50.0922 51.7811L38.7601 73.5008H11.6996L0.791811 51.6853C-0.400234 49.3012 -0.237354 46.4636 1.21967 44.2315L25.2227 7.46094ZM25.2666 16.5407L5.40656 46.9646C4.92089 47.7087 4.8666 48.6545 5.26395 49.4492L14.7897 68.5008H35.7292L45.6592 49.4683C46.0814 48.6592 46.0291 47.6844 45.5229 46.9251L25.2666 16.5407Z" fill="white"/>
                        <path className="services-slider-svg__path services-slider-svg__path_fill" fillRule="evenodd" clipRule="evenodd" d="M10.7451 68.5H39.7451V86.5H10.7451V68.5ZM15.7451 73.5V81.5H34.7451V73.5H15.7451Z" fill="white"/>
                        <path className="services-slider-svg__path services-slider-svg__path_fill" fillRule="evenodd" clipRule="evenodd" d="M27.7451 12V48H22.7451V12H27.7451Z" fill="white"/>
                    </svg>
                );
            case 3:
                return(
                    <svg className="services-slider-svg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="services-slider-svg__path services-slider-svg__path_fill" d="M71 20.9238L67.7543 21.8354L9.66614 38.7766H0V58.224H9.66868L14.659 59.6679L14.5829 59.894V59.9718C12.955 65.1059 15.9699 70.8138 21.5561 72.4279C21.5815 72.4352 21.6119 72.4206 21.6322 72.4279C27.0155 73.9958 32.9744 71.1395 34.6303 65.7428L34.7063 65.5143L67.7543 75.1651L71 76.0743V20.9238ZM65.9286 27.4582V69.5424L10.8529 53.4351L10.541 53.3621H5.07143V43.6384H10.5384L10.8554 43.5655L65.9286 27.4557V27.4582ZM19.492 61.1095L29.8733 64.0752L29.7972 64.3013V64.3742C28.9249 67.2184 25.7755 68.6089 23.0623 67.7945C20.0955 66.9607 18.569 64.0169 19.416 61.4134V61.3404L19.492 61.1095Z" fill="white"/>
                    </svg>
                );
            case 4:
                return(
                    <svg className="services-slider-svg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="services-slider-svg__path services-slider-svg__path_fill" d="M62.9181 18.8589C59.5475 18.0946 56.1204 18.1751 52.6642 18.1737C44.9092 18.1737 38.6752 20.3127 34.6312 24.3567C31.4366 27.5493 28.2615 30.7613 25.1062 33.9927C21.4642 33.5817 12.8752 33.3837 7.05524 39.2067C6.77532 39.4845 6.55315 39.8149 6.40154 40.1789C6.24993 40.543 6.17188 40.9334 6.17188 41.3277C6.17188 41.7221 6.24993 42.1125 6.40154 42.4765C6.55315 42.8406 6.77532 43.171 7.05524 43.4487L40.9942 77.3877C43.2004 79.3719 43.2004 79.4225 45.2362 77.3877C51.0982 71.5287 50.9182 62.9457 50.5372 59.3577L60.0892 49.8087C69.6622 40.2357 66.0038 25.5445 64.9752 19.3731C64.9752 19.3731 63.4452 18.9902 62.9181 18.8589ZM55.8472 45.5697L45.2392 56.1747C44.8901 56.5239 44.6327 56.9539 44.4898 57.4265C44.3469 57.8991 44.323 58.3996 44.4202 58.8837C44.4352 58.9587 45.6412 65.6097 42.8122 70.7157L13.7272 41.6337C18.8272 38.8197 25.5502 40.1217 25.5712 40.1217C26.5612 40.3317 27.6232 40.0137 28.3402 39.2727C28.3492 39.2637 32.5582 34.9137 38.8762 28.5957C42.5302 24.9417 48.3892 24.1737 52.6642 24.1737C55.5052 24.1737 58.0432 24.5067 59.6602 24.7827C60.3922 29.0757 61.5352 39.8787 55.8472 45.5697Z" fill="white"/>
                        <path className="services-slider-svg__path services-slider-svg__path_fill" d="M47.1826 42.9141C50.4963 42.9141 53.1826 40.2278 53.1826 36.9141C53.1826 33.6004 50.4963 30.9141 47.1826 30.9141C43.8689 30.9141 41.1826 33.6004 41.1826 36.9141C41.1826 40.2278 43.8689 42.9141 47.1826 42.9141Z" fill="white"/>
                        <path className="services-slider-svg__path services-slider-svg__path_fill" d="M15 60C9 63 9 75 9 75C9 75 18 75 24 69L15 60Z" fill="white"/>
                    </svg>
                );
            case 5:
                return(
                    <svg className="services-slider-svg" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="services-slider-svg__path services-slider-svg__path_fill" d="M26.625 16.5V23.3203C24.1809 25.2012 22.5342 28.084 22.2742 31.3711C21.0435 33.041 18.7554 35.6777 16.7966 39.3516L12.0991 34.6055L8.97901 37.7695L14.8899 43.7812C14.0059 46.3301 13.3992 49.2129 13.3472 52.5H4.4375V57H13.5898C13.8672 59.9355 14.5085 62.6602 15.4099 65.1914L8.97901 71.7129L12.0991 74.9121L17.4033 69.5332C17.802 70.2363 18.166 70.9746 18.6167 71.625C21.8235 76.1074 26.625 78.832 32.1372 79.2188C32.1545 79.2188 32.1719 79.2188 32.1719 79.2188C32.2239 79.2539 32.2759 79.2891 32.3452 79.3066C32.3799 79.3418 32.4319 79.3594 32.4666 79.3594C32.5186 79.3945 32.5532 79.4121 32.6052 79.4297C32.6919 79.4473 32.7959 79.4824 32.8999 79.5C32.9346 79.5 32.9866 79.5176 33.0386 79.5176C33.1252 79.5352 33.2292 79.5352 33.3333 79.5176C33.4893 79.5176 33.6279 79.5 33.7666 79.4824C33.9399 79.4297 34.1133 79.377 34.2693 79.2891C34.304 79.2715 34.356 79.2539 34.3906 79.2188C34.408 79.2188 34.408 79.2188 34.4253 79.2188C39.9375 78.832 44.739 76.1074 47.9458 71.625C48.3965 70.9746 48.7605 70.2363 49.1592 69.5332L54.446 74.9121L57.6008 71.7129L51.1526 65.1914C52.054 62.6602 52.6953 59.9355 52.9727 57H62.125V52.5H53.198C53.1633 49.3887 52.6086 46.5938 51.7939 44.1328L57.6528 37.7168L54.394 34.6582L49.8525 39.5977C47.8418 35.748 45.519 33.0586 44.271 31.3359C43.9937 28.084 42.3816 25.2012 39.9375 23.3203V16.5H35.5V21.2285C34.7893 21.0879 34.0439 21 33.2812 21C32.5186 21 31.7732 21.0879 31.0625 21.2285V16.5H26.625ZM33.2812 25.5C36.2107 25.5 38.6375 27.3809 39.5388 30H27.0237C27.9251 27.3809 30.3518 25.5 33.2812 25.5ZM25.5503 34.5H31.0625V74.4375C27.5437 73.8223 24.4929 72.1523 22.2222 68.9707C19.4487 65.1211 17.75 59.4785 17.75 52.9219C17.75 43.6406 22.6035 38.4199 25.5503 34.5ZM35.5 34.5H41.0122C43.959 38.4199 48.8125 43.6406 48.8125 52.9219C48.8125 59.4785 47.1138 65.1211 44.3403 68.9707C42.0696 72.1523 39.0188 73.8223 35.5 74.4375V34.5Z" fill="white"/>
                    </svg>
                );
            default:
                break;
        }
    }

    return (
        <section className="services">
          <div className="services__wrapper container">
          <div className="clutch-widget" data-tb-embedded-award data-tb-embedded-award-style="banner" data-tb-embedded-award-id="movadex" />
          </div>
          <div className="services__wrapper container" style={{ marginTop: "64px"}}>
            <h2 className={`services__title${!isMediaSmall && step > 0 ? " hide" : ""}`}>Services we provide</h2>
                <p className={`services__text${!isMediaSmall && step > 0 ? " hide" : ""}`}>Leveraging our in-depth expertise, we quickly and effectively deliver a leading solution for your needs. Capitalize on your market potential, increase cash flows, and gauge interest among your clientele by upgrading your strategy, design, software development, data analytics and machine learning.</p>

                {isMediaSmall ? null : <div className="services__block">
                    <svg className="services__arrow" onClick={() => changeSlide("prev")} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"/>
                    <path d="M26.5 19L22 23.5L26.5 28" stroke="#1958E3" strokeWidth="3"/>
                    </svg>

                    <svg className="services__arrow" onClick={() => changeSlide("next")} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="24" cy="24" r="23.5" stroke="#1958E3"/>
                    <path d="M22 19L26.5 23.5L22 28" stroke="#1958E3" strokeWidth="3"/>
                    </svg>

                    <h3 className="services-step">
                        0{step + 1}/
                        <span className="services-step__span">0{total}</span>
                    </h3>
                </div>}

                <div className="services-slider">
                    <div className="services-slider__wrapper" style={isMediaSmall ? null : {transform: `translateX(${32 - step * (174 / total)}%)`}}>
                        {sliderData.map(({ node }, index) =>
                        <Link to={`${node.slug}`} className={`services-slider__item${!isMediaSmall && index === step ? " active" : ""}${isMediaSmall ? " active" : ""}`} key={index}>
                            <div className="services-slider__icon">
                                {getSvgIcon(index)}
                            </div>

                            <h3 className="services-slider__title">{node.title}</h3>

                            <p className="services-slider__text">{node.text}</p>

                            <p className="services-slider-more">
                                explore more
                                <svg className="services-slider-more__svg" width="49" height="8" viewBox="0 0 49 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.5" d="M48.3536 4.35355C48.5488 4.15829 48.5488 3.8417 48.3536 3.64644L45.1716 0.464462C44.9763 0.2692 44.6597 0.2692 44.4645 0.464462C44.2692 0.659724 44.2692 0.976307 44.4645 1.17157L47.2929 4L44.4645 6.82842C44.2692 7.02369 44.2692 7.34027 44.4645 7.53553C44.6597 7.73079 44.9763 7.73079 45.1716 7.53553L48.3536 4.35355ZM4.37114e-08 4.5L48 4.5L48 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z" fill="white"/>
                                </svg>
                            </p>
                        </Link>)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SliderServices;
