import React from "react"

const StandWithUkraine = () => {
  return (
    <div className="stand-with-ukraine">
      <span className="title">#StandWithUkraine</span>
      <span>To support Ukraine, please consider donating to the listed channels</span>
      <a href="https://www.openpetition.eu/petition/online/people-around-the-world-ask-nato-to-close-the-airspace-over-ukraine">→ Special Account to Raise Funds for Ukraine’s Armed Forces </a>
      <a href="https://savelife.in.ua/">→ The Charity Foundation “Come back alive”</a>
    </div>
  )
}

export default StandWithUkraine
