import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useSwipeable } from "react-swipeable"

const SliderClients = () => {
    const data = useStaticQuery(graphql`
    query SliderClientsQuery {
        allSliderClientsDataJson {
            edges {
                node {
                    id
                    photo
                    name
                    company
                    text
                }
            }
        }
    }    
    `)

    const sliderData = data.allSliderClientsDataJson.edges;

    const [step, setStep] = useState(0);
    const total = sliderData.length;

    const handleStep = action => {
        switch (action) {
            case "prev":
                if(step === 0) {
                    setStep(total - 1)
                } else {
                    setStep(step - 1);
                }
                break;
        
            case "next":
                if(step === total - 1) {
                    setStep(0)
                } else {
                    setStep(step + 1);
                }
                break;        
            default:
                break;
        }
    }

    const swipeObject = {
        onSwipedLeft: () => handleStep("next"),
        onSwipedRight: () => handleStep("prev"),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true
    };

    const handlerSwipe = useSwipeable(swipeObject);

    return (
        <section className="reviews">
            <div className="reviews__wrapper container">
                <h2 className="reviews__title">Clients say about us</h2>

                <div className="reviews__block">
                    <h3 className="reviews-step">
                        0{step + 1}/
                        <span className="reviews-step__span">0{total}</span>
                    </h3>

                    {sliderData.map(({ node }, index) => step === index ?
                    <div {...handlerSwipe} className="reviews-view" key={node.id}>
                        <h4 className="reviews-view__title">{node.name}</h4>
                        
                        <p className="reviews-view__subtitle">{node.company}</p>

                        <p className="reviews-view__text">{node.text}</p>
                    </div>
                    : null
                    )}

                    <div className="reviews-control">
                        <svg className="reviews-control__svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleStep("prev")}>
                            <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"/>
                            <path d="M26.5 19L22 23.5L26.5 28" stroke="#1958E3" strokeWidth="3"/>
                        </svg>

                        <svg className="reviews-control__svg" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleStep("next")}>
                            <circle cx="24" cy="24" r="23.5" stroke="#1958E3"/>
                            <path d="M22 19L26.5 23.5L22 28" stroke="#1958E3" strokeWidth="3"/>
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SliderClients;
