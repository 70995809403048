import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"

import scrollTo from "gatsby-plugin-smoothscroll"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SEO from "../mainComponents/seo"

import awardOne from "../assets/home/award-1.svg"
import awardTwo from "../assets/home/award-2.png"
import awardThree from "../assets/home/award-3.svg"
import awardFour from "../assets/home/award-4.svg"

import LogoOne from "../assets/home/portfolio-1.svg"
import LogoTwo from "../assets/home/portfolio-2.svg"
import LogoThree from "../assets/home/portfolio-3.svg"
import LogoFour from "../assets/home/portfolio-4.svg"
import LogoFive from "../assets/home/portfolio-5.svg"
import LogoSix from "../assets/home/portfolio-6.svg"
import LogoSeven from "../assets/home/portfolio-7.svg"
import LogoEight from "../assets/home/portfolio-8.svg"
import LogoNine from "../assets/home/portfolio-9.svg"
import LogoTen from "../assets/home/portfolio-10.svg"
import LogoEvelen from "../assets/home/portfolio-11.svg"
import LogoTwelve from "../assets/home/portfolio-12.svg"
import LogoThirteen from "../assets/home/portfolio-13.svg"
import LogoFourteen from "../assets/home/portfolio-14.svg"
import LogoFifteen from "../assets/home/portfolio-15.svg"
import LogoSixteen from "../assets/home/portfolio-16.svg"
import LogoSeventeen from "../assets/home/portfolio-17.svg"
import LogoEighteen from "../assets/home/portfolio-18.svg"
import LogoNineteen from "../assets/home/portfolio-19.svg"
import LogoTwenty from "../assets/home/portfolio-20.svg"

import LogoOneGray from "../assets/home/portfolio-1-gray.svg"
import LogoTwoGray from "../assets/home/portfolio-2-gray.svg"
import LogoThreeGray from "../assets/home/portfolio-3-gray.svg"
import LogoFourGray from "../assets/home/portfolio-4-gray.svg"
import LogoFiveGray from "../assets/home/portfolio-5-gray.svg"
import LogoSixGray from "../assets/home/portfolio-6-gray.svg"
import LogoSevenGray from "../assets/home/portfolio-7-gray.svg"
import LogoEightGray from "../assets/home/portfolio-8-gray.svg"
import LogoNineGray from "../assets/home/portfolio-9-gray.svg"
import LogoTenGray from "../assets/home/portfolio-10-gray.svg"
import LogoEvelenGray from "../assets/home/portfolio-11-gray.svg"
import LogoTwelveGray from "../assets/home/portfolio-12-gray.svg"
import LogoThirteenGray from "../assets/home/portfolio-13-gray.svg"
import LogoFourteenGray from "../assets/home/portfolio-14-gray.svg"
import LogoFifteenGray from "../assets/home/portfolio-15-gray.svg"
import LogoSixteenGray from "../assets/home/portfolio-16-gray.svg"
import LogoSeventeenGray from "../assets/home/portfolio-17-gray.svg"
import LogoEighteenGray from "../assets/home/portfolio-18-gray.svg"
import LogoNineteenGray from "../assets/home/portfolio-19-gray.svg"
import LogoTwentyGray from "../assets/home/portfolio-20-gray.svg"

import SliderServices from "../components/SliderServices"
import SliderTech from "../components/SliderTech"
import ReviewsSection from "../components/ReviewsSection"

import makeUrlValid from "../components/makeUrlValid"

import VideoSrc from "../assets/new-home.mp4"
import Wrapper from "../mainComponents/Wrapper"

import "../style/index.scss"
import StandWithUkraine from "../components/StandWithUkraine"

export const query = graphql`
  query IndexBlogQuery {
    allArticles: allStrapiArticles {
      edges {
        node {
          strapiId
          Preview {
            publicURL
          }
          Title
          categories {
            id
            Tag
          }
          author {
            Photo {
              publicURL
            }
            Name
            Position
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const articles = data.allArticles.edges

  useEffect(() => {
    let e = document.querySelector("[data-tb-embedded-award]")
    if (e) {
      let t = e.getAttribute("data-tb-embedded-award-style"), a = e.getAttribute("data-tb-embedded-award-id")
      if (a) {
        let d = window.location.href
        if ("banner" === t && (e.innerHTML = "<iframe data-tb-award-frame src=\"https://techbehemoths.com/awards/embedded/2021?style=" + t + "&company-id=" + a + "&referrer=" + d + "\" frameborder=\"0\" width=\"100%\" height=\"215px\" scrolling=\"no\"></iframe>") && "badge" === t) {
          let r = e.getAttribute("data-tb-embedded-award-theme"),
            i = e.getAttribute("data-tb-embedded-award-location"),
            n = e.getAttribute("data-tb-embedded-award-service")
          if (!r || !i || !n) return
          e.innerHTML = "<iframe data-tb-award-frame src=\"https://techbehemoths.com/awards/embedded/2021?style=" + t + "&company-id=" + a + "&service=" + n + "&theme=" + r + "&referrer=" + d + "\" width=\"410px\" height=\"284px\" frameborder=\"0\" scrolling=\"no\"></iframe>"
        }
        window.onmessage = e => {
          e = e.data.hasOwnProperty("tbFrameHeight") ? e.data.tbFrameHeight : null
          e && e.length < 8 && document.querySelector("[data-tb-award-frame]").setAttribute("height", e)
        }
      }
    }
  }, [])

  return (
    <Wrapper>
      <Layout>
        <SEO title="Movadex | Home" description={"Award-winning software design and development agency. We build great products providing efficient process and clear communication."}/>
        <section className="home">
          <div className="home__block container">
            <h1 className="home__title">Design and software development done right</h1>

            <p className="home__text">We make your ideas succeed through design and software development.</p>

            <div className="home-link" onClick={() => {
              scrollTo("#portfolio")
            }}>
              Explore latest cases

              <svg className="home-link__svg" width="23" height="16" viewBox="0 0 23 16" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.760076 8.63992L19.6601 8.63992L13.7801 14.5199C14.6801 15.4199 13.7801 14.5198 14.6801 15.4199L21.6601 8.45992L22.1001 7.99992L21.6601 7.53992L14.6801 0.579922C13.7801 1.47992 14.6801 0.579922 13.7801 1.47992L19.6601 7.35992L0.760076 7.35992C0.759685 8.63992 0.759506 7.35992 0.760076 8.63992Z"
                  fill="white" />
              </svg>
            </div>
          </div>

          <div className="home-card">
            <div className="home-card__item">
              <h2 className="home-card__title">
                <span className="home-card__title_span">7+</span>
                <span className="home-card__title_show">7+</span>
              </h2>
              <h6 className="home-card__subtitle">years of proven experience</h6>
            </div>

            <div className="home-card__item">
              <h2 className="home-card__title">
                <span className="home-card__title_span">200+</span>
                <span className="home-card__title_show">200+</span>
              </h2>
              <h6 className="home-card__subtitle">projects outsourced</h6>
            </div>

            <div className="home-card__item">
              <h2 className="home-card__title">
                <span className="home-card__title_span">50+</span>
                <span className="home-card__title_show">50+</span>
              </h2>
              <h6 className="home-card__subtitle">satisfied customers</h6>
            </div>

            <div className="home-card__item">
              <h2 className="home-card__title">
                <span className="home-card__title_span">65+</span>
                <span className="home-card__title_show">65+</span>
              </h2>
              <h6 className="home-card__subtitle">talented professionals</h6>
            </div>
          </div>
          <span className="ukraine-will-live">We continue to work in full power, Ukraine will live!</span>

          <div className="home-video" dangerouslySetInnerHTML={{
            __html: `
        <video
          loop
          muted
          autoplay
          playsinline="true"
          src="${VideoSrc}"
          class="home-video__item"
        ></video>
        <!-- Video made by One Man Wolf Pack (https://www.youtube.com/channel/UCLlw7TAl8zmJa4vGFKu1c_Q) -->
      `,
          }} />
        </section>

        <StandWithUkraine />

        <SliderServices />

        <SliderTech />

        <section className="awards">
          <div className="awards__wrapper container">
            <div className="awards__block">
              <h6 className="awards__subtitle">Awards</h6>

              <h2 className="awards__title">Reviews and recognition</h2>

              <p className="awards__text">
                100% client satisfaction, flawless perfromance of our products, and a leading approach to R&D has been
                the center of our operations.
                <span className="awards__span">We were honored to have recieved awards, recognitions, and nominations globally accross various industries.</span>
              </p>
            </div>

            <div className="awards__block">
              <div className={`awards-card`}>
                <img className="awards-card__logo" src={awardOne} alt="Clutch" />
                <p className="awards-card__text">
                  Clutch is an independent platform that collects objective feedback from clients about each company
                  registered on the site.<span className="awards-card__span" /></p>
              </div>

              <div className={`awards-card`}>
                <img className="awards-card__logo" src={awardTwo} alt="Appfutura award" />
                <p className="awards-card__text">AppFutura is a marketplace where mobile platform app developers can
                  meet people or companies that are looking for someone to develop an app project.<span
                    className="awards-card__span" /></p>
              </div>

              <div className={`awards-card`}>
                <img className="awards-card__logo" src={awardThree} alt="GoodFirms award" />
                <p className="awards-card__text">GoodFirms is a full-fledged research and review platform that helps
                  software buyers and service seekers to opt for the best software or firm.<span
                    className="awards-card__span" /></p>
              </div>

              <div className={`awards-card`}>
                <img className="awards-card__logo" src={awardFour} alt="Yahoo award" />
                <p className="awards-card__text">Movadex was identified as one of the leading design and development companies that build
                  appealing and fully functional applications for brands to engage more audiences.<span
                    className="awards-card__span" /></p>
              </div>
            </div>
            <div className="clutch-widget" style={{ marginTop: "64px" }} data-url="https://widget.clutch.co"
                 data-widget-type="8"
                 data-expandifr="true" data-height="auto" data-clutchcompany-id="675281" />
          </div>
        </section>

        <section className="clients">
          <div className="clients__wrapper container">
            <h3 className="clients__title">Our happy clients</h3>

            <div className="clients__block">
              <div className="clients__source">
                <img className="clients__gray" src={LogoOneGray} alt="Company" />
                <img className="clients__image" src={LogoOne} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoTwoGray} alt="Company" />
                <img className="clients__image" src={LogoTwo} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoThreeGray} alt="Company" />
                <img className="clients__image" src={LogoThree} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoFourGray} alt="Company" />
                <img className="clients__image" src={LogoFour} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoFiveGray} alt="Company" />
                <img className="clients__image" src={LogoFive} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoSixGray} alt="Company" />
                <img className="clients__image" src={LogoSix} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoSevenGray} alt="Company" />
                <img className="clients__image" src={LogoSeven} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoEightGray} alt="Company" />
                <img className="clients__image" src={LogoEight} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoNineGray} alt="Company" />
                <img className="clients__image" src={LogoNine} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoTenGray} alt="Company" />
                <img className="clients__image" src={LogoTen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoEvelenGray} alt="Company" />
                <img className="clients__image" src={LogoEvelen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoTwelveGray} alt="Company" />
                <img className="clients__image" src={LogoTwelve} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoThirteenGray} alt="Company" />
                <img className="clients__image" src={LogoThirteen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoFourteenGray} alt="Company" />
                <img className="clients__image" src={LogoFourteen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoFifteenGray} alt="Company" />
                <img className="clients__image" src={LogoFifteen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoSixteenGray} alt="Company" />
                <img className="clients__image" src={LogoSixteen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoSeventeenGray} alt="Company" />
                <img className="clients__image" src={LogoSeventeen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoEighteenGray} alt="Company" />
                <img className="clients__image" src={LogoEighteen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoNineteenGray} alt="Company" />
                <img className="clients__image" src={LogoNineteen} alt="Company" />
              </div>
              <div className="clients__source">
                <img className="clients__gray" src={LogoTwentyGray} alt="Company" />
                <img className="clients__image" src={LogoTwenty} alt="Company" />
              </div>
            </div>

            <a className="clients__link" onClick={() => {
              scrollTo("#footer-mail")
            }}>Become a Client</a>
          </div>
        </section>

        <ReviewsSection />

        <section className="whyus">
          <div className="container">
            <h2 className="whyus__title">Why choose us</h2>
          </div>

          <div className="whyus_border">
            <div className="whyus-card container">
              <div className="whyus-card__item">
                <div className="whyus-card_flex">
                  <h3 className="whyus-card__title">Leading Work Ethic</h3>
                  <p className="whyus-card__text">We use Figma, an interactive design tool so you can see the progress
                    and communicate with our designers

                    <span className="whyus-card__span">SCRUM-certified and will ensure the project delivery is time- and cost-optimized so you can enjoy the flawless performance of your software in a timely manner.</span>
                  </p>
                </div>

                <h2 className="whyus-card__index">01</h2>
              </div>

              <div className="whyus-card__item">
                <div className="whyus-card_flex">
                  <h3 className="whyus-card__title">Transparent financial framework</h3>
                  <p className="whyus-card__text">We estimate the pricing using the standard workflow frameworks and
                    offer various payment options. You will not come across any unexpected bills or hidden fees.

                    <span className="whyus-card__span">Our legal team will provide you with service agreement, a detailed invoice, and an acceptance of transmission to ensure the transparency and your satisfaction.</span>
                  </p>
                </div>

                <h2 className="whyus-card__index">02</h2>
              </div>

              <div className="whyus-card__item">
                <div className="whyus-card_flex">
                  <h3 className="whyus-card__title">Transmission of Proprietary Rights</h3>
                  <p className="whyus-card__text">We always emphasize the importance of forming a long-term partnership
                    with our clients. That’s why we have a 97% retention rate.

                    <span className="whyus-card__span">However, if you choose to terminate our collaboration and look for other services, you will have proprietary rights over the source code and our developers will execute the full transmission of the materials.</span>
                  </p>
                </div>

                <h2 className="whyus-card__index">03</h2>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio" id="portfolio">
          <div className="container">
            <h6 className="portfolio__subtitle">Latest cases</h6>

            <h2 className="portfolio__title">Our portfolio</h2>

            <p className="portfolio__text">Fostering creativity, we create never-seen-before solutions for each and
              every client, exceeding industry standards and customer expectations.</p>

            <div className="portfolio__block">
              <Link to="/cases/astrolife/" className="portfolio-item">
                <h4 className="portfolio-item__title">Astrolife</h4>
                <p className="portfolio-item__tags">UI/UX, Development</p>
              </Link>

              <Link to="/cases/pyoor/" className="portfolio-item">
                <h4 className="portfolio-item__title">Pyoor</h4>
                <p className="portfolio-item__tags">Web-development, UI/UX</p>
              </Link>

              <Link to="https://www.movadex.com/cases/tastebuds/" className="portfolio-item">
                <h4 className="portfolio-item__title">TasteBuds</h4>
                <p className="portfolio-item__tags">UI/UX, iOS, Android</p>
              </Link>
              <Link className="portfolio-item">
                <h4 className="portfolio-item__title">Free Vestia</h4>
                <p className="portfolio-item__tags">Web-development, UI/UX, Branding</p>
              </Link>

              <Link to="https://apexcapitalrealty.com/" className="portfolio-item">
                <h4 className="portfolio-item__title">Apex</h4>
                <p className="portfolio-item__tags">Android, iOS, iPadOS, UI/UX</p>
              </Link>


              <Link to="/cases/flight-simulator/" className="portfolio-item">
                <h4 className="portfolio-item__title">Flight Simulator</h4>
                <p className="portfolio-item__tags">iOS (iPadOS), UI/UX, Branding</p>
              </Link>


              <Link to="/cases/turtlequeue/" className="portfolio-item">
                <h4 className="portfolio-item__title">Turtlequeue</h4>
                <p className="portfolio-item__tags">Web-development, UI/UX</p>
              </Link>

              <Link to="/cases/startify/" className="portfolio-item">
                <h4 className="portfolio-item__title">Startify</h4>
                <p className="portfolio-item__tags">Web-development, Product design, Marketing</p>
              </Link>

              <Link to="/cases/kyiv-metro-app/" className="portfolio-item">
                <h4 className="portfolio-item__title">Kyiv Metro App</h4>
                <p className="portfolio-item__tags">UI/UX, iOS, Android</p>
              </Link>


              <Link to="/cases/uprice/" className="portfolio-item">
                <h4 className="portfolio-item__title">Uprice</h4>
                <p className="portfolio-item__tags">Android, iOS, Product design, Branding</p>
              </Link>


              <Link to="/cases/znaj-ua/" className="portfolio-item">
                <h4 className="portfolio-item__title">ZNAJ.UA</h4>
                <p className="portfolio-item__tags">Android, iOS, iPadOS, UI/UX</p>
              </Link>

              <Link to="https://fairplay-management.de/en/" className="portfolio-item">
                <h4 className="portfolio-item__title">Fairplay</h4>
                <p className="portfolio-item__tags">Web-development, UI/UX</p>
              </Link>


              <Link to="/cases/plan-b/" className="portfolio-item">
                <h4 className="portfolio-item__title">PlanB</h4>
                <p className="portfolio-item__tags">UI/UX, Branding</p>
              </Link>

              <Link to="/cases/legends-pro/" className="portfolio-item">
                <h4 className="portfolio-item__title">LegendsPro</h4>
                <p className="portfolio-item__tags">UI/UX, Development</p>
              </Link>

              <Link to="/cases/klt-health/" className="portfolio-item">
                <h4 className="portfolio-item__title">KLT Health</h4>
                <p className="portfolio-item__tags">Web-development, UI/UX</p>
              </Link>

              {/*<Link to="/cases/medicus-ug/" className="portfolio-item">*/}
              {/*  <h4 className="portfolio-item__title">Medicus Ug</h4>*/}
              {/*  <p className="portfolio-item__tags">UI/UX, Branding</p>*/}
              {/*</Link>*/}

              <Link to="/survey/" className="portfolio-item">
                <h4 className="portfolio-item__title_add">Add my project here</h4>
              </Link>
            </div>
          </div>
        </section>

        <section className="news">
          <div className="news__wrapper container">

            <h6 className="news__subtitle">Our blog</h6>

            <h2 className="news__title">Movadex news</h2>

            <div className="blog__block">
              {articles
                .sort((a, b) => a.node.strapiId < b.node.strapiId ? 1 : b.node.strapiId < a.node.strapiId ? -1 : 0)
                .slice(0, 3)
                .map(({ node }, index) =>
                  <Link to={`/blog/article/${makeUrlValid(node.Title)}`} className="blog-item" key={node.strapiId}>
                    <div className="blog-item__block">
                      <p className="blog-item__view">Read an article</p>
                      {console.log(node)}
                      <img
                        className="blog-item__image"
                        src={node.Preview.publicURL}
                        alt=""
                      />
                    </div>

                    <div className="blog-item__block">
                      {node.categories.map(elem => (
                        <span
                          key={elem.id}
                          className={`blog-item__tag blog-item__tag_${elem.Tag.toLowerCase()}`}
                        >
                    #{elem.Tag.toLowerCase()}
                  </span>
                      ))}
                    </div>

                    <h5 className="blog-item__title">{node.Title}</h5>

                    <div className="blog-author">
                      <div className="blog-author__image">
                        <img
                          className="blog-author__source"
                          src={node.author.Photo.publicURL}
                          alt=""
                        />
                      </div>

                      <div className="blog-author__block">
                        <h6 className="blog-author__title">{node.author.Name}</h6>
                        <p className="blog-author__text">{node.author.Position}</p>
                      </div>
                    </div>
                  </Link>,
                )}
            </div>

          </div>

          <Link className="news__link" to="/blog/">
            <span className="news__link_span">View all articles</span>
          </Link>
        </section>
      </Layout>

      <Footer>
        <FooterMail />
      </Footer>
    </Wrapper>
  )
}

export default IndexPage
